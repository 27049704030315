import { GetStaticProps } from 'next';
import { isEmpty } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { useRouter } from 'next/router';
import NextError from 'next/error';

import { captureMessage } from '@/utils/sentry';
import { ContentBlocks } from '@/components/content-blocks';
import { getPageBySlug } from '@/lib/datocms/api';
import { Layout } from '@/components/layout';
import { Page, PagePaths } from '@/lib/types';
import { PAGE_REVALIDATE_INTERVAL, SLUGS } from '@/lib/constants';

type PageNotFoundProps = { page: Page | null; paths: PagePaths | null };

export const getStaticProps: GetStaticProps<PageNotFoundProps> = async () => {
  const data = await getPageBySlug({ slug: SLUGS.PAGE_NOT_FOUND });

  if (!data || !data.page) {
    return {
      props: { page: null, paths: null },
    };
  }

  const { page, paths } = data;
  return {
    props: { page, paths },
    revalidate: PAGE_REVALIDATE_INTERVAL,
  };
};

const PageNotFound = ({ page, paths }: PageNotFoundProps): ReactElement => {
  const { asPath } = useRouter();

  useEffect(() => {
    captureMessage('Page Not Found', { extra: { path: asPath } });
  }, [asPath]);

  if (!page || !paths || isEmpty(page.content)) {
    return <NextError statusCode={404} />;
  }

  return (
    <Layout paths={paths} metaTags={page.metaTags}>
      <ContentBlocks blocks={page.content} />
    </Layout>
  );
};

export default PageNotFound;
